











































import ChapterRepository from "@/api/ChapterRepository"
import TrackingRepository from "@/api/TrackingRepository"
import LoadingSpinner from "@/components/LoadingSpinner.vue"
import { Chapter } from "@/models/Chapter"
import { InfoText } from "@/models/InfoText"
import { AssetHelper } from "@/utils/AssetHelper"
import { Component, Vue, Prop } from "vue-property-decorator"
import MarkdownItVue from "markdown-it-vue"
import "markdown-it-vue/dist/markdown-it-vue.css"

@Component({
  components: {
    MarkdownItVue,
    LoadingSpinner
  }
})
export default class InfoTextDetail extends Vue {
  @Prop({ type: Object, default: () => ({}) }) infoText!: InfoText
  chapters: Chapter[] = []
  isLoading: boolean = true

  async created(): Promise<void> {
    this.chapters = await ChapterRepository.getInfoTextChapters(this.infoText.id)
    this.isLoading = false
  }
  async log(chapterTitle: String): Promise<void> {
    let groupID = "INFO:"
    await TrackingRepository.createLog(
      groupID + "Klick auf Kapitel '" + chapterTitle + "' von Artikel '" + this.infoText.title + "'"
    )
  }
  getDefaultBackground(): any {
    return AssetHelper.getDefaultInfoCardImage()
  }
}
