import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VListItem } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{ref:"searchBarInfoText",attrs:{"data-cy":"infosearchbar","rounded":"","solo":"","clearable":"","items":_vm.tags,"label":_vm.$t('searchbar.label'),"item-text":"titleTranslation","return-object":"","no-data-text":_vm.$t('searchbar.no-data')},on:{"input":function($event){return _vm.handleInputChange(_vm.selectedTag)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('i18n',{attrs:{"path":item.category + '.' + item.title}})]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c(VListItem,_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('div',{staticClass:"searchItem"},[_c('i18n',{attrs:{"path":item.category + '.' + item.title}})],1)])]}}]),model:{value:(_vm.selectedTag),callback:function ($$v) {_vm.selectedTag=$$v},expression:"selectedTag"}})}
var staticRenderFns = []

export { render, staticRenderFns }