


























import { InfoText } from "@/models/InfoText"
import { AssetHelper } from "@/utils/AssetHelper"
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({})
export default class InfoTextCard extends Vue {
  @Prop({ type: Number, default: 0 }) index!: number
  @Prop({ type: Object, default: () => ({}) }) infoText!: InfoText
  @Prop({ type: String, default: "" }) footerText!: string

  getDefaultBackground(): any {
    return AssetHelper.getDefaultInfoCardImage()
  }

  get isInfoTextNotified(): boolean {
    const notifiedTextIds = this.$store.getters.getNotifiedInfoTextIds
    return notifiedTextIds.includes(this.infoText.id)
  }
}
