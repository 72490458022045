



























import { Tag } from "@/models/Tag";
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class SearchBarInfoText extends Vue {
  @Prop({type: Array, default: () => []}) tags!: Tag[]
  @Prop({type: Array, default: () => []}) customTags!: Tag[]
  selectedTag: string = ''

  handleInputChange(item: Tag): void {
    // @ts-ignore
    this.$refs["searchBarInfoText"]!.blur();
    this.$emit("selectedTagChanged", item);
  }
}
