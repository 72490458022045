





















































import { Component, Vue, Prop } from "vue-property-decorator"
import InfoTextCard from "@/components/info/components/InfoTextCard.vue"

@Component({
  components: {
    InfoTextCard
  }
})
export default class InfoTextCarousel extends Vue {
  @Prop({ type: String, default: "" }) title!: string
  @Prop({ type: Array, default: () => [] }) infoTexts!: any[]
  @Prop({ type: Boolean, default: false }) isElevated!: boolean
  currentIndex: number = 0

  get numInfoTexts(): number {
    return this.infoTexts.length
  }
  get numInfoTextsRecommended(): number {
    return this.infoTexts.filter((infoText: any) => infoText.is_recommended).length
  }
}
