














import { AssetHelper } from "@/utils/AssetHelper"
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({})
export default class SearchResultCard extends Vue {
  @Prop({ type: Number, default: 0 }) index!: number
  @Prop({ type: Object, default: () => ({}) }) infoText!: object

  getDefaultBackground(): any {
    return AssetHelper.getDefaultInfoCardImage()
  }
}
