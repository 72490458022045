









import { Component } from 'vue-property-decorator'
import VueOnbHint from './VueOnbHint'
import Speechbubble from '@/components/onboarding/Speechbubble.vue';

@Component({
    components: {
        Speechbubble
    }
})
export default class OnbInfoHints extends VueOnbHint {
    hintOrder: string[] = ["recommended_category_title", "other_categories_title"]
    hintClasses: string[] = ["recommededInfotextTitle", "infotextTitle"]
    onboardingSetting: object = {information_hints: false}
    cssClass: string = "yc_onb_hint_circle_info"

    setHighlight(){
        // do not set highlight, if no class to look up specified
        if(this.hintClasses[this.hintStep] === "")
            return 

        let id = this.hintClasses[this.hintStep];
        let els = document.getElementsByClassName(id);
        let prevs = null;

        if (this.hintStep > 0) 
            prevs = document.getElementsByClassName(this.hintClasses[this.hintStep - 1]);
        else if (this.hintStep == this.hintOrder.length - 1)
            prevs = document.getElementsByClassName(this.hintClasses[this.hintStep]);

        if (els) {
            Array.from(els).forEach((el) => {
                el.classList.add(this.cssClass);
                //@ts-ignore
                el.style.zIndex = 250 + "!important";
            });
        }
        
        // remove highlight from prev if pev is set
        if(prevs){
            Array.from(prevs).forEach(prev => {
                prev.classList.remove(this.cssClass);
                //@ts-ignore
                prev.style.zIndex = "100";
            });
        }
    }

    mounted() {
        this.disableScrolling(true);
        this.setHighlight();
        this.disableAnchors(true);
        this.changeFixedNav(true);
    }
    beforeDestroy(){
        this.disableAnchors(false);
        this.disableScrolling(false);
        this.removeAllHighlights();
        this.changeFixedNav(false);
    }
}
