




































































import { Vue, Component, Watch } from "vue-property-decorator"
import CarouselInfoText from "@/components/info/components/CarouselInfoText.vue"
import InfoTextDetail from "@/components/info/components/InfoTextDetail.vue"
import SearchResultCard from "@/components/info/components/SearchResultCard.vue"
import SearchBarInfoText from "@/components/info/components/SearchBarInfoText.vue"
import OnbInfoHints from "@/components/onboarding/OnbInfoHints.vue"
import { Tag } from "@/models/Tag"
import { InfoText } from "@/models/InfoText"
import TrackingRepository from "@/api/TrackingRepository"

@Component({
  components: {
    SearchResultCard,
    CarouselInfoText,
    InfoTextDetail,
    SearchBarInfoText,
    OnbInfoHints
  }
})
export default class InfoTextView extends Vue {
  selectedTag: Tag | null = null
  tags: Tag[] = []
  infoTexts: InfoText[] = []
  isDetailViewDisplayed: boolean = false
  selectedInfoText: InfoText | null = null
  showInfoHints: boolean = false

  get infoHintStatus(): boolean {
    return this.$store.getters.getOnboardingSetting("information_hints")
  }

  get filteredInfoTexts(): InfoText[] {
    if (this.selectedTag) {
      return this.infoTexts.filter((infoText: InfoText) =>
        infoText.tags.some((_tag) => _tag.title === this.selectedTag!.title)
      )
    }
    return this.infoTexts
  }
  get infoTextsRecommended(): InfoText[] {
    return this.filteredInfoTexts.filter((infoText: InfoText) => infoText.is_recommended)
  }
  get infoTextsDiagnoses(): InfoText[] {
    return this.filteredInfoTexts.filter((infoText: InfoText) => infoText.type === "DIA")
  }
  get infoTextsTherapies(): InfoText[] {
    return this.filteredInfoTexts.filter((infoText: InfoText) => infoText.type === "THE")
  }
  get infoTextsExaminations(): InfoText[] {
    return this.filteredInfoTexts.filter((infoText: InfoText) => infoText.type === "EXA")
  }
  get infoTextsPsychology(): InfoText[] {
    return this.filteredInfoTexts.filter((infoText: InfoText) => infoText.type === "PSY")
  }
  get infoTextsHelpingServices(): InfoText[] {
    return this.filteredInfoTexts.filter((infoText: InfoText) => infoText.type === "HEL")
  }

  @Watch("infoHintStatus")
  onInfoHintStatusChange() {
    this.showInfoHints = this.infoHintStatus
  }

  async created(): Promise<void> {
    this.infoTexts = await this.loadInfoTexts()
    this.tags = await this.loadTags()

    this.showInfoHints = this.$store.getters.getOnboardingSetting("information_hints")
  }

  async changeSelectedTag(tag: Tag) {
    this.selectedTag = tag
    let groupID = "INFO:"
    await TrackingRepository.createLog(
      groupID + "Suche Info mit Begriff " + this.$t("" + tag.category + "." + tag.title)
    )
  }
  async openTextDetailView(
    data: { infoText: InfoText; isInfoTextNotified: boolean },
    fromRecommendations: boolean = false
  ) {
    this.isDetailViewDisplayed = true
    this.selectedInfoText = data.infoText

    const suffix = fromRecommendations ? " aus Dashboard" : ""
    let groupID = "INFO:"
    await TrackingRepository.createLog(
      groupID +
        "Klick auf Artikel '" +
        data.infoText.title +
        "' (" +
        data.infoText.type +
        suffix +
        ")"
    )

    if (data.isInfoTextNotified)
      this.$store.dispatch("markInfoTextNotificationsAsSeen", data.infoText)
  }

  closeTextDetailView(): void {
    this.isDetailViewDisplayed = false
    this.selectedInfoText = null
    this.selectedTag = null
  }
  async loadTags(): Promise<Tag[]> {
    let tags = new Array<Tag>()
    await this.$store.dispatch("loadTags")
    tags = this.$store.state.tag.tags.tags
    tags.map(
      (tag: Tag) =>
        (tag.titleTranslation = this.$t(tag.category + "." + tag.title)
          .toString()
          .replaceAll("{0}", ""))
    )
    return tags
  }
  async loadInfoTexts(): Promise<InfoText[]> {
    await this.$store.dispatch("loadInfoTexts")

    return this.$store.state.infoText.infoTexts
  }
  async loadInfoTextChapters(infoTextId: number): Promise<boolean> {
    await this.$store.dispatch("loadInfoTextChapters", infoTextId)
    this.infoTexts = this.$store.state.infoText.infoTexts
    return true
  }
}
