import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"yc_info_text_detail_container spacebottom"},[_c('div',{staticClass:"yc_info_text_detail_header"},[_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c('p',{staticClass:"yc_info_text_detail_title"},[_vm._v(_vm._s(_vm.infoText.title))]),_c('div')],1),_c('div',{staticClass:"yc_image_description_container"},[_c('div',{staticClass:"yc_image_container"},[_c('img',{staticClass:"yc_image_fit",attrs:{"src":_vm.infoText.image != null ? _vm.infoText.image : _vm.getDefaultBackground()}})]),_c('pre',{staticClass:"yc_info_text_detail_description"},[_vm._v("      "),_c('MarkdownItVue',{attrs:{"content":_vm.infoText.description}}),_vm._v("\n    ")],1)]),(_vm.isLoading)?_c('LoadingSpinner'):_c('div',_vm._l((_vm.chapters),function(chapter,i){return _c(VExpansionPanels,{key:i,staticClass:"yc_options_list",attrs:{"max":1,"light":"","accordion":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{on:{"click":function($event){return _vm.log(chapter.title)}}},[_c('pre',{staticClass:"yc_info_text_detail_subheadline"},[_vm._v(_vm._s(chapter.title))])]),_c(VExpansionPanelContent,{staticClass:"yc_eventsearch_filter_content"},[_c('pre',{staticClass:"yc_info_text_detail_description"},[_vm._v("            "),_c('MarkdownItVue',{attrs:{"content":chapter.body}}),_vm._v("\n          ")],1)])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }